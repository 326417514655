/*
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import {SiteMetaProvider} from '@myadbox/nebula-helmet'
import {graphql, useStaticQuery} from 'gatsby'

export const GatsbySiteMetaProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <SiteMetaProvider
      initialValue={{
        title: site.siteMetadata.title,
        description: site.siteMetadata.description,
      }}
    >
      {children}
    </SiteMetaProvider>
  )
}

import {IconTab, type HasConditionalIcon} from '@myadbox/stellar-ui'
import {Link} from 'gatsby-plugin-react-i18next'
import usePartiallyActive from '../../hooks/usePartiallyActive/usePartiallyActive'

type NavVariants = `mobile` | `default`

export type NavItemProps<T = NonNullable<unknown>> = HasConditionalIcon & {
  to: string
  passData: T
  label: string
}

interface Props {
  variant?: NavVariants
  items?: NavItemProps[]
}

export const variantClassNames = {
  mobile: `
    lg:hidden
    until-lg:flex
    until-lg:bottom-0
    until-lg:left-0
    until-lg:right-0
    until-lg:fixed
    bg-ui-200
    shadow-inner
    dark:bg-ui-50
    z-20
  `,
  default: `
    lg:flex
    lg:flex-col
    lg:pt-18
    lg:w-18
    until-lg:hidden
    left-0
    absolute
  `,
}

const TabbedNav = ({variant = `default`, items}: Props) => {
  if (!items) return null
  return (
    <ul
      className={`
        ${variantClassNames[variant] ? variantClassNames[variant] : ``}
      `}
    >
      {items.map((item, index) => {
        return <TabItem {...item} key={index} />
      })}
    </ul>
  )
}

export default TabbedNav

const TabItem = (props: NavItemProps) => {
  const isActive = usePartiallyActive(props.to)
  return (
    <li className="inline-block flex-1">
      <IconTab {...props} as={Link} isActive={isActive} />
    </li>
  )
}

import {useUserSession} from '@myadbox/gatsby-theme-nebula/hooks'
import {Seo} from '@myadbox/nebula-helmet'
import {useFeatureFlags} from '@myadbox/nebula-launchdarkly'
import {PageActionHeader} from '@myadbox/nebula-layouts'
import {useAccountBilling, useBilling} from '@myadbox/nebula-service-api'
import {Divider} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import {SettingsTabComponentName} from '../helpers'
import AccountBillingForm from './AccountBillingForm'
import BillingAdjustmentModal from './AccountBillingForm/BillingAdjustmentModal'
import SendInvoiceConfirmationModal from './AccountBillingForm/SendInvoiceConfirmationModal'

export const Billing = () => {
  const {t} = useTranslation()
  const {isAccountAdmin, isSesimiAdmin} = useUserSession()

  const {
    featureFlags: {showBillingScreen},
  } = useFeatureFlags()

  const {
    fetchAccountBilling,
    fetchAccountBillingResult: {data: billingData, error: billingError},
  } = useAccountBilling()

  const {
    createAndSendInvoice,
    createAndSendInvoiceResponse: {
      data: createAndSendInvoiceData,
      error: createAndSendInvoiceError,
      loading: createAndSendInvoiceLoading,
    },
    fetchInvoices,
    fetchInvoicesResults: {
      data: invoiceData,
      error: invoicesError,
      loading: invoicesLoading,
    },
  } = useBilling()

  useEffect(() => {
    fetchAccountBilling()
  }, [fetchAccountBilling])

  const errorMsg =
    billingError?.message ||
    createAndSendInvoiceError?.message ||
    invoicesError?.message

  if (!showBillingScreen) return <> </>

  return (
    <>
      <Seo title={t`settings.billing.title`} />
      <PageActionHeader text={t`settings.billing.subtitle`}>
        {isSesimiAdmin && billingData?.accountBilling && (
          <div className="flex gap-2">
            <BillingAdjustmentModal
              autoBilling={billingData.accountBilling?.billing?.autoBilling}
              price={billingData.accountBilling?.billing?.price / 100 || 0}
              currency={billingData.accountBilling.billing?.currency || `aud`}
              billingEmail={billingData.accountBilling?.billing?.billingEmail}
              paymentTerm={
                billingData.accountBilling?.billing?.paymentTerm || 0
              }
              additionalInfo={
                billingData.accountBilling.billing?.additionalInfo
              }
            />
            <SendInvoiceConfirmationModal
              account={billingData.accountBilling}
              createAndSendInvoice={createAndSendInvoice}
              createAndSendInvoiceData={
                createAndSendInvoiceData?.createAndSendInvoice
              }
              loading={createAndSendInvoiceLoading}
              error={createAndSendInvoiceError?.message}
            />
          </div>
        )}
      </PageActionHeader>
      <Divider spanGrid />
      {(isSesimiAdmin || isAccountAdmin) && (
        <div
          className={`
          align-items:center
          font-size:300
          mb-4
          grid
          grid-cols-3
          items-center
          gap-4
          text-sm
        `}
        >
          <AccountBillingForm
            fetchInvoices={fetchInvoices}
            createAndSendInvoiceData={
              createAndSendInvoiceData?.createAndSendInvoice
            }
            invoices={invoiceData?.invoices}
            invoicesLoading={invoicesLoading}
            errorMsg={errorMsg}
            accountBilling={billingData?.accountBilling}
          />
        </div>
      )}
    </>
  )
}

Billing.displayName = `Billing` satisfies SettingsTabComponentName
export default Billing

import {LockClosedIcon} from '@heroicons/react/20/solid'
import {UserGroupIcon} from '@heroicons/react/24/outline'
import {Seo} from '@myadbox/nebula-helmet'
import {PageActionHeader} from '@myadbox/nebula-layouts'
import {Team, User, useProfiles, useTeams} from '@myadbox/nebula-service-api'
import {
  Avatar,
  Banner,
  Table,
  TableEmptyState,
  TableLoadingState,
  TableScrollWrap,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useState} from 'react'
import {tableHelpers} from '../../../utils/tableHelpers'
import {SettingsTabComponentName} from '../helpers'
import DeleteTeamModal from './DeleteTeamModal'
import TeamModal from './TeamModal'

const Teams = () => {
  const {t} = useTranslation()
  const [teams, setTeams] = useState<Team[]>([])
  const [profiles, setProfiles] = useState<User[]>([])
  const {
    fetchAllTeams,
    allTeamsResult: {
      called: teamsCalled,
      loading: teamsLoading,
      error: teamsError,
      data: teamsData,
    },
  } = useTeams()

  const {
    fetchAllProfiles,
    allProfilesResult: {
      called: profilesCalled,
      loading: profilesLoading,
      error: profilesError,
      data: profileData,
    },
  } = useProfiles()

  useEffect(() => {
    fetchAllProfiles()
    fetchAllTeams()
  }, [fetchAllProfiles, fetchAllTeams])

  useEffect(() => {
    teamsData?.getTeams && setTeams(teamsData.getTeams)
    profileData?.allUsersByAccountId &&
      setProfiles(profileData.allUsersByAccountId)
  }, [teamsData, profileData])

  const called = teamsCalled || profilesCalled
  const loading = teamsLoading || profilesLoading
  const error = teamsError || profilesError

  return (
    <>
      <Seo title="Teams" />
      <PageActionHeader text={t`settings.teams.subtitle`}>
        <div>{called && !loading && <TeamModal users={profiles} isNew />}</div>
      </PageActionHeader>

      <TableScrollWrap>
        <Table contrast>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>{t`settings.teams.teamNameCol`}</Table.HeadCell>
              <Table.HeadCell>{t`settings.teams.membersCol`}</Table.HeadCell>
              <Table.HeadCell
                style={{width: tableHelpers.actionsWidth}}
              >{t`settings.categories.tableHeaders.actions`}</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {called &&
              teams.map((team: Team): ReactNode => {
                return (
                  <Table.Row key={team.id}>
                    <Table.Cell>
                      <div
                        className={`
                          relative
                          pl-10
                        `}
                      >
                        <div
                          className={`
                            absolute
                            left-0
                            top-[50%]
                            -translate-y-[50%]
                          `}
                        >
                          <Avatar team src={team.avatar} title={team.name} />
                        </div>
                        <div
                          className={`
                            flex
                            items-center
                            gap-2
                          `}
                        >
                          <span>{team.name}</span>
                          {team.private && (
                            <LockClosedIcon
                              width={12}
                              height={12}
                              className={`text-ui-300`}
                            />
                          )}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {`${team.userIds.length}  ${
                        team.userIds.length === 1
                          ? t`settings.teams.member`
                          : t`settings.teams.members`
                      } `}
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        className={`
                          text-ui-400
                          dark:text-ui-300
                          -ml-3
                        `}
                      >
                        <TeamModal users={profiles} team={team} isNew={false} />
                        {`|`}
                        <DeleteTeamModal team={team} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
      </TableScrollWrap>

      {loading && <TableLoadingState />}
      {error && (
        <div className={`my-4`}>
          <Banner>{t`settings.error`}</Banner>
        </div>
      )}
      {called && !loading && !teams.length && (
        <TableEmptyState
          icon={<UserGroupIcon width={36} height={36} />}
          text={t`settings.teams.emptyState`}
        />
      )}
    </>
  )
}

Teams.displayName = `Teams` satisfies SettingsTabComponentName
export default Teams

import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import {Seo} from '@myadbox/nebula-helmet'
import {
  AccountIntegration,
  Integration,
  useAccount,
  useAccountIntegrations,
} from '@myadbox/nebula-service-api'
import {Badge, Banner, Button, Text, Toast} from '@myadbox/stellar-ui'
import {Link, useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import IntegrationForm from '../IntegrationForm'
import {
  ExportAppList,
  badgeNameTranslation,
  isExportWithEnablement,
  renderLogo,
} from '../helpers'
import ConfirmationModal from './ConfirmationModal'

interface Props {
  integration: Integration
  accountIntegration?: AccountIntegration
  accountId?: string
}

export const IntegrationView = ({
  integration,
  accountIntegration,
  accountId,
}: Props) => {
  const {
    t,
    i18n: {language},
  } = useTranslation()

  const {accountName} = useAccount()
  const {
    updateAccountIntegration,
    updateAccountIntegrationResponse: {loading, error, data, reset},
  } = useAccountIntegrations(accountName)
  const isDefaultIntegration =
    !!integration.isEnabledByDefault && !!integration.isActive
  useEffect(() => {
    if (!loading && !error && data) {
      Toast.show(
        <Toast intent="success">
          {t`settings.integrations.connectModal.successMsg`}
        </Toast>
      )
      reset()
    }
  }, [loading, error, data, t, reset])

  if (!integration) return null

  const baseStyle = `grid grid-flow-row gap-6 sm:pr-8`
  const styledCss = {
    withoutForm: `w-2/3 sm:grid-cols-[minmax(50%,1fr)] xl:grid-cols-[minmax(50%,1fr)]`,
    withForm: `sm:grid-cols-[minmax(50%,1fr)_minmax(30%,1fr)] xl:grid-cols-[minmax(50%,1fr)_minmax(30%,1fr)_minmax(20%,1fr)]`,
    withBorder: `${baseStyle} sm:border-ui-50 sm:border-r-2`,
  }
  return (
    <div>
      <Seo title="Integrations" />
      <div className="flex items-center">
        <Button
          slim
          size="xs"
          variant="text"
          as={Link}
          title={t`settings.integrations.backBtnTitle`}
          to="/settings/integrations/"
        >
          <ArrowLeftIcon width={20} height={20} />
        </Button>
        <Text variant="body">
          {t(`settings.integrations.header`, {name: integration.name})}
        </Text>
      </div>
      <div>
        {error && (
          <div className="my-4">
            <Banner fadeIn intent="error">
              {error.message}
            </Banner>
          </div>
        )}
      </div>
      <div
        className={`
          bg-ui-100
          dark:bg-ui-100
          rounded-400
          mt-4
          grid
          grid-flow-row
          gap-8
          p-8
          sm:grid-flow-col
          ${
            isDefaultIntegration || isExportWithEnablement(integration.name)
              ? styledCss.withoutForm
              : styledCss.withForm
          }
        `}
      >
        <div
          className={`${
            isDefaultIntegration || isExportWithEnablement(integration.name)
              ? baseStyle
              : styledCss.withBorder
          }`}
        >
          <div className="flex w-1/6 flex-row items-center gap-3">
            <div>{renderLogo(integration)}</div>
            <div className="">
              <Badge>
                {(integration.category &&
                  t(`${badgeNameTranslation[integration.category]}`)) ||
                  `Unknown`}
              </Badge>
            </div>
          </div>

          <Text variant="heading">{integration.name}</Text>
          <Text variant="body">
            {integration.description[language] ||
              integration.description[`en-AU`]}
          </Text>
          {!isDefaultIntegration && (
            <div>
              <ConfirmationModal
                updateAccountIntegration={updateAccountIntegration}
                accountIntegration={accountIntegration}
                accountId={accountId}
                label={integration.name}
                srcId={integration.id}
              />
            </div>
          )}
        </div>

        {!isDefaultIntegration && !isExportWithEnablement(integration.name) && (
          <div>
            <IntegrationForm
              integration={integration}
              accountIntegration={accountIntegration}
              hideButton={integration.name === ExportAppList.GoogleSheets}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default IntegrationView

import {ReactNode, createContext, useContext} from 'react'
import Helmet from 'react-helmet'

interface Props {
  description?: string
  lang?: string
  meta?: {
    name: string
    content: string
  }[]
  title?: string
}

type SiteMeta = {title: string; description: string}

const StateContext = createContext<SiteMeta | null>(null)
StateContext.displayName = `SiteMeta StateContext`

export const SiteMetaProvider = ({
  initialValue,
  children,
}: {
  initialValue: SiteMeta
  children: ReactNode
}): JSX.Element => {
  return (
    <StateContext.Provider value={initialValue}>
      {children}
    </StateContext.Provider>
  )
}

const defaultSiteMeta = {title: ``, description: ``}

export const useSiteMeta = () => {
  const state = useContext(StateContext)
  return state || defaultSiteMeta
}

export const Seo = ({
  description = ``,
  lang = `en`,
  meta = [],
  title = ``,
}: Props) => {
  const siteMeta = useSiteMeta()
  const metaDescription = description || siteMeta.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMeta.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

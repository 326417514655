import {Avatar} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import useUserSession from '../../../../hooks/useUserSession'
import ColourSchemeToggler from '../../../ColourSchemeToggler/ColourSchemeToggler'
import Dropdown from '../../../Dropdown'
import {SizeClassNames} from '../../../Dropdown/helpers'
import LocalisedLink from '../../../LocalisedLink'
import {HelpMenu, IntercomTour} from '../HelpMenu'

type SupportProps = {
  user?: {
    avatar?: string
    fullName?: string
  }
  tours?: IntercomTour[]
}

const sizeClassNames: SizeClassNames = `w-[3rem] h-[3rem]`

const Support = ({user, tours}: SupportProps) => {
  const {t} = useTranslation(`layout`)
  const {isAccountAdmin} = useUserSession()

  return (
    <div
      className={`
        flex
        items-center
        gap-2
        self-stretch
        pl-3
      `}
    >
      <HelpMenu tours={tours} />
      <ColourSchemeToggler />

      <Dropdown
        /**
         * This is a workaround to ensure the icon is always visible,
         * rather than flickering in when Reach Dropdown renders the trigger button after checking `isMounted()`
         */
        triggerAppearance={
          <Dropdown.TriggerPlaceholder
            className={`group-hover:text-ui-900 text-ui-400 p-2`}
          >
            <Avatar src={user?.avatar} title={user?.fullName} />
          </Dropdown.TriggerPlaceholder>
        }
        triggerSizeClassNames={sizeClassNames}
        trigger={
          <Dropdown.Button
            unstyled
            className={`
              hocus:bg-ui-100
              rounded-full
              px-2
            `}
            aria-label={t(`toggleUserMenu`)}
            title={t(`toggleUserMenu`)}
            id="user-menu-trigger"
          />
        }
      >
        {/* <Dropdown.Item to="/notifications/">Notifications</Dropdown.Item> */}
        {isAccountAdmin && (
          <Dropdown.Item
            to="/settings/account/"
            as={LocalisedLink}
          >{t`settings`}</Dropdown.Item>
        )}
        <Dropdown.Item
          to="/profile/user/"
          as={LocalisedLink}
        >{t`profile`}</Dropdown.Item>
        <Dropdown.Item
          to="/logout/"
          as={LocalisedLink}
        >{t`signout`}</Dropdown.Item>
      </Dropdown>
    </div>
  )
}

export default Support

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import {ApolloProvider} from '@apollo/client'
import {
  GatsbyPluginsOptionsAdapter,
  GatsbySiteMetaProvider,
  UserSessionProvider,
  returnToLogin,
} from '@myadbox/gatsby-theme-nebula'
import {initializeMixpanel} from '@myadbox/mixpanel'
import {FeatureFlagsProvider} from '@myadbox/nebula-launchdarkly'
import {isPublicPage} from '@myadbox/nebula-routes'
import {getClient} from '@myadbox/nebula-service-api'
import {GatsbyBrowser} from 'gatsby'

export const onClientEntry = () => {
  const MIXPANEL_TOKEN = process.env.GATSBY_MIXPANEL_TOKEN || ``
  const mixPanelDebug = process.env.GATSBY_MIXPANEL_DEBUG === `true`
  if (MIXPANEL_TOKEN) {
    initializeMixpanel(MIXPANEL_TOKEN, mixPanelDebug)
  } else {
    console.error(`Mixpanel token is missing!`)
  }
}

export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element,
  props: {
    location: {pathname},
  },
}) => {
  if (isPublicPage(pathname)) {
    return <FeatureFlagsProvider>{element}</FeatureFlagsProvider>
  }

  return <UserSessionProvider>{element}</UserSessionProvider>
}

const client = getClient({
  errorCallback: returnToLogin,
  gateway: process.env.GATSBY_SERVICES_API_GATEWAY,
  devtools: process.env.GATSBY_ENABLE_APOLLO_DEV_TOOLS === `true`,
})

export const wrapRootElement: GatsbyBrowser[`wrapRootElement`] = ({
  element,
}) => {
  return (
    <GatsbySiteMetaProvider>
      <GatsbyPluginsOptionsAdapter>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </GatsbyPluginsOptionsAdapter>
    </GatsbySiteMetaProvider>
  )
}

// /**
//  * This function replaces the default `hydrate` function in Gatsby.
//  * It's very definitely a hack to hide hydration errors, but since
//  * Gatsby's DEV_SSR flag no longer works, it's prohibitively difficult
//  * to debug them.
//  */
// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container)
//     root.render(element)
//   }
// }

import {RectangleStackIcon} from '@heroicons/react/24/outline'
import {Seo} from '@myadbox/nebula-helmet'
import {PageActionHeader} from '@myadbox/nebula-layouts'
import {
  Category,
  User,
  useCategories,
  useProfiles,
} from '@myadbox/nebula-service-api'
import {
  Banner,
  Table,
  TableEmptyState,
  TableLoadingState,
  TableScrollWrap,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useState} from 'react'
import {tableHelpers} from '../../../utils/tableHelpers'
import {SettingsTabComponentName} from '../helpers'
import CategoriesModal from './CategoriesModal'
import CategoryRow from './CategoryRow'
import {generateProfilesMap, getCategoryTree} from './helpers'

const Categories = () => {
  const {t} = useTranslation()
  const [categories, setCategories] = useState<Category[]>([])
  const [profiles, setProfiles] = useState<User[]>([])

  const {
    fetchCategories,
    fetchCategoriesResponse: {
      called: categoryCalled,
      data: categoryData,
      error: categoryError,
      loading: categoryLoading,
    },
  } = useCategories()

  const {
    fetchProfilesByIds,
    profilesByIdsResult: {
      called: profileCalled,
      data: profileData,
      error: profileError,
      loading: profileLoading,
    },
  } = useProfiles()

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData.categories)
      const createdByUserIds = categoryData.categories.map(
        ({createdById}) => createdById
      )
      fetchProfilesByIds(createdByUserIds)
    }
  }, [categoryData, fetchProfilesByIds])

  useEffect(() => {
    if (profileData) {
      setProfiles(profileData.profilesByUserIds)
    }
  }, [profileData])

  const loading = categoryLoading || profileLoading
  const error = categoryError || profileError
  const called = categoryCalled && profileCalled

  const categoriesTree = getCategoryTree(categories, `title`)

  const profilesMap = generateProfilesMap(profiles)

  return (
    <>
      <Seo title="Categories" />
      <PageActionHeader text={t`settings.categories.subtitle`}>
        <CategoriesModal categories={categories} />
      </PageActionHeader>

      <TableScrollWrap>
        <Table contrast>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell className={`pl-3`}>
                {t`settings.categories.tableHeaders.categories`}
              </Table.HeadCell>
              <Table.HeadCell>
                {t`settings.categories.tableHeaders.subCategoriesCount`}
              </Table.HeadCell>
              <Table.HeadCell>
                {t`settings.categories.tableHeaders.active`}
              </Table.HeadCell>
              <Table.HeadCell>
                {t`settings.categories.tableHeaders.createdAt`}
              </Table.HeadCell>
              <Table.HeadCell>
                {t`settings.categories.tableHeaders.createdBy`}
              </Table.HeadCell>
              <Table.HeadCell style={{width: tableHelpers.actionsWidth}}>
                {t`settings.categories.tableHeaders.actions`}
              </Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {!loading &&
              categories.length !== 0 &&
              categoriesTree.map(
                (category): ReactNode => (
                  <CategoryRow
                    key={category.id}
                    category={category}
                    categories={categories}
                    profilesMap={profilesMap}
                  />
                )
              )}
          </Table.Body>
        </Table>
      </TableScrollWrap>

      {loading && <TableLoadingState />}
      {error && (
        <div className={`my-4`}>
          <Banner>{t`settings.error`}</Banner>
        </div>
      )}
      {called && categories.length === 0 && (
        <TableEmptyState
          icon={<RectangleStackIcon width={36} height={36} />}
          text={t`settings.categories.emptyState`}
        />
      )}
    </>
  )
}

Categories.displayName = `Categories` satisfies SettingsTabComponentName
export default Categories

import {CopyDetail} from '@myadbox/gatsby-theme-nebula'
import {Asset, useAccount, useProfiles} from '@myadbox/nebula-service-api'
import {AssetType} from '@myadbox/nebula-template-utils'
import {Checkbox, Input, Text, TextArea} from '@myadbox/stellar-ui'
import {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {useFeatureFlags} from '@myadbox/nebula-launchdarkly'

const DEFAULT_WIDTH = `640`
const DEFAULT_HEIGHT = `360`
const RESPONSIVE_SIZE = `100%`

const SocialsEmbed = ({assets}: {assets: Asset[]}) => {
  const {register, watch, setValue} = useForm({
    defaultValues: {
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
      makeResponsive: false,
      addBackLink: false,
    },
  })
  const {t} = useTranslation(`socialsIntegrations`)
  const {account} = useAccount()
  const {profile} = useProfiles()
  const {
    featureFlags: {showEmbedLinkbackCheckbox},
  } = useFeatureFlags()

  const width = watch(`width`)
  const height = watch(`height`)
  const makeResponsive = watch(`makeResponsive`)
  const backLink = watch(`addBackLink`)

  // Adjust width and height based on "Make Responsive" checkbox
  const embedWidth = makeResponsive ? RESPONSIVE_SIZE : width
  const embedHeight = makeResponsive ? RESPONSIVE_SIZE : height

  const embedCode = useMemo(() => {
    if (!assets.length || !profile) return ``

    const {id, name, versions, type: assetType} = assets[0]
    const {userId} = profile

    const payload = {
      uid: userId,
      acc: account?.id,
    }

    const base64Payload = window.btoa(JSON.stringify(payload))
    const activeVersion =
      versions.length === 1
        ? versions[0]
        : versions.find(version => version.active)

    const backLinkwrap = (child: string) =>
      showEmbedLinkbackCheckbox && backLink
        ? `<a href="${window.location.href}" target="blank">${child}</a>`
        : child

    if (!activeVersion) return ``

    if (assetType === AssetType.Video) {
      const videoSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/video/upload/${id}?p=${base64Payload}`
      const videoTag = `<iframe width="${embedWidth}" height="${embedHeight}" src="${videoSrc}" frameborder="0" allowfullscreen></iframe>`
      return backLinkwrap(videoTag)
    } else {
      const imgSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/image/upload/${id}?p=${base64Payload}`
      const imgTag = `<img alt="${name}" src="${imgSrc}" />`
      return backLinkwrap(imgTag)
    }
  }, [
    assets,
    profile,
    account?.id,
    showEmbedLinkbackCheckbox,
    backLink,
    embedWidth,
    embedHeight,
  ])

  return (
    <>
      <Text>{t(`embed.code`)}</Text>
      {!!assets.length && assets[0].type === AssetType.Video && (
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <Input
              id="width"
              label={t(`embed.width`)}
              type="number"
              {...register(`width`)}
              className="rounded border p-2"
              disabled={makeResponsive}
            />
            <Input
              id="height"
              label={t(`embed.height`)}
              type="number"
              {...register(`height`)}
              className="rounded border p-2"
              disabled={makeResponsive}
            />
          </div>
          <label className="flex items-center gap-2">
            <Checkbox
              {...register(`makeResponsive`)}
              onChange={e => {
                const isChecked = e.target.checked
                setValue(`makeResponsive`, isChecked)
                setValue(`width`, isChecked ? RESPONSIVE_SIZE : DEFAULT_WIDTH)
                setValue(`height`, isChecked ? RESPONSIVE_SIZE : DEFAULT_HEIGHT)
              }}
            />
            {t(`embed.responsive`)}
          </label>
        </div>
      )}

      <TextArea
        id="embedCode"
        name="embedCode"
        label={t(`embed.code`)}
        labelHidden
        value={embedCode}
        rows={7}
        disabled
        noResize
      />
      <label className="flex items-center gap-2">
        <Checkbox
          {...register(`addBackLink`)}
          onChange={e => {
            const isChecked = e.target.checked
            setValue(`addBackLink`, isChecked)
          }}
        />
        {t(`embed.backLink`)}
      </label>

      <div className="transition-property-default active:bg-ui-50 active:text-ui-800 hover:border-ui-500 hover:text-ui-700 text-ui-600 dark:hover:border-ui-300 relative inline-flex items-center justify-center whitespace-nowrap rounded border px-4 py-2 text-left text-sm font-medium leading-5 focus:border-lime-300">
        <CopyDetail title={t(`embed.copy`)} copyText={embedCode}>
          <Text aria-label={t(`embed.copy`) || ``}>{t(`embed.copy`)}</Text>
        </CopyDetail>
      </div>
    </>
  )
}

export default withTranslationProvider(SocialsEmbed)
